/* @flow */

import type { QuizListItemRes as TQuizListItem } from "shop-state/types";

import React, { useContext } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useTranslate } from "@awardit/react-use-translate";
import useFormat from "helpers/use-format";
import { StoreInfoContext } from "entrypoint/shared";
import { useData } from "crustate/react";
import { QuizHistoryListData } from "data";

import styles from "./styles.scss";

const QuizListItem = ({ item }: { item: TQuizListItem }) => {
  const t = useTranslate();
  const { formatPoints } = useFormat();
  const questions = item.count;
  const { routes } = useContext(StoreInfoContext);
  const quizHistoryListData = useData(QuizHistoryListData);
  const quizHistoryLinkUrl = routes.quizHistory && routes.quizHistory.url ? routes.quizHistory.url : "";
  const quizListLinkUrl = routes.quizList && routes.quizList.url ? routes.quizList.url : "";

  const currentQuizHistory = quizHistoryListData.state === "LOADED" &&
  quizHistoryListData.data.length > 0 ?
    quizHistoryListData.data.find(elem =>
      elem.offerid === parseInt(item.id, 10)) :
    null;

  const quizhistoryUrl = () => {
    if (currentQuizHistory) {
      return `${quizHistoryLinkUrl}/${item.id}/${currentQuizHistory.id}`;
    }

    return "#";
  };

  if (item.answered >= item.maxusage) {
    return (
      <div className={cn(
        styles.block,
        { [styles.blockAnswered]: routes.quizHistory && !routes.quizHistory.toggle }
      )}
      >
        <div className={styles.shadow} />
        {item.image && <img src={item.image} />}
        <div className={styles.top}>
          <p
            className={cn(
              styles.status,
              styles.status__closed
            )}
          >
            {t(`QUIZ_ITEM.STATUS.ANSWERED`)}
          </p>
          <header>
            <h2 className={styles.title}>{item.name}</h2>
          </header>
        </div>
        <div className={styles.bottom}>
          <p>{t("QUIZ_ITEM.NUM_QUESTIONS", { questions })}</p>
          <p>{formatPoints(item.points)}</p>
        </div>
        {routes.quizHistory && routes.quizHistory.toggle !== undefined &&
        routes.quizHistory.toggle &&
        <Link className={styles.bottomLink} to={quizhistoryUrl()}>{t("QUIZ_ITEM.SHOW_ANSWER")}</Link>}
      </div>
    );
  }

  return (
    <Link
      className={cn(styles.block, styles.open, [item.answered === 1 && styles.answered])}
      to={`${quizListLinkUrl}/${item.id}?type=${item.showmode}`}
    >
      <div className={styles.shadow} />
      {item.image && <img src={item.image} />}
      <div className={styles.top}>

        <header>
          <h2 className={styles.title}>{item.name}</h2>
        </header>
      </div>
      <div className={styles.bottom}>
        <p>{formatPoints(item.points)}</p>
        <p>{t("QUIZ_ITEM.NUM_QUESTIONS", { questions })}</p>
      </div>
    </Link>
  );
};

export const DummyQuizListItem = () => (
  <div className={styles.dummy_block}>
    <div className={styles.dummy_top}>
      <div className={styles.dummy_date} />
    </div>
    <div className={styles.dummy_title} />
  </div>
);

export default QuizListItem;
